/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.slider').slick({
          lazyLoad: 'progressive',
          autoplay: true,
          dots: true,
          autoplaySpeed: 3000,
          speed: 800,
          fade: true,
          cssEase: 'linear',
          infinite: true,
          pauseOnHover: false,
          waitForAnimate: false
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Run code for each element
         $('.embed-responsive').each( function (){

         // Get ar
         var ar = $(this).attr('data-aspectratio');

         // Check for existance and set padding-bottom
         if (typeof ar !== typeof undefined && ar !== false ) {
           $(this).css('padding-bottom', (1/ar)*100+'%');
         }

         });

        if ( $( ".gallery" ).length ) {

          $( ".gallery" ).each( function( i, container ) {

            var $container_gallery = $('.gallery');

            $container_gallery.imagesLoaded( function() {
              $container_gallery.masonry({
                itemSelector: '.gallery-item'
              });
            });

          });

        }

        $('.slider').show();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_kuvagalleria': {
      init: function() {

        // console.log('jep');

        var container = document.querySelector('.gallerywrap');

        imagesLoaded( container, function() {
        var msnry = new Masonry( container, {
            // options...
            columnWidth: ".grid-sizer",
            itemSelector: '.gallerythumb'
          });
        });

      },
      finalize: function() {

      }
    },
    'page_template_template_yrityshaku': {
      init: function() {

        // console.log('jep');

        $('#cat').selectric();

        $('#cat').on('change', function() {
          $('.yrityslinkki').hide();
          if ( this.value === '0' ) {
            console.log(this.value);
            $('.yrityslinkki').show();
          } else {
            $('.'+this.value).toggle();
          }
        });

      },
      finalize: function() {

      }
    },
    'single_yritys': {
      init: function() {

        // console.log('jep');

        $('#cat').selectric();

        $('#cat').on('change', function() {
          $('.yrityslinkki').hide();
          if ( this.value === '0' ) {
            console.log(this.value);
            $('.yrityslinkki').show();
          } else {
            $('.'+this.value).toggle();
          }
        });

      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/* Script for asynchronus load of sharing buttons */

 (function(w, d, s) {

   function go(){
    var js, fjs = d.getElementsByTagName(s)[0], load = function(url, id) {
    if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.src = url; js.id = id;
      fjs.parentNode.insertBefore(js, fjs);
    };
        //Facebook
    load('//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.7&appId=1379074455440593', 'facebook-jssdk');
        //Google+
    load('https://apis.google.com/js/plusone.js', 'gplus1js');
        //Twitter
    load('//platform.twitter.com/widgets.js', 'tweetjs');
        //LinedIN
    // load('//platform.linkedin.com/in.js', 'lnkdjs');
        //Pinterest
    load('//assets.pinterest.com/js/pinit.js', 'pinitjs');
    }
  if (w.addEventListener) { w.addEventListener("load", go, false); }
    else if (w.attachEvent) { w.attachEvent("onload",go); }

}(window, document, 'script'));
